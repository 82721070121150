<template>
  <!-- <div class="regist-main"> -->
    <div style="margin: 20px;text-align: center;">
    <div class="text-home">
      <img style="zoom:0.06" src="../../../src/assets/img/logo.png" />
    </div>
    <h1 class="brand-info__text" style="line-height: 60px;text-align: center;margin:auto;">欢迎登录心狗报告快速查询通道</h1>
    <el-tabs v-model="activeName">
      <el-form :label-position="labelPosition" :model="mobileForm" ref="mobileForm" @keyup.enter.native="mobileFormSubmit()" status-icon>
          <el-form-item >
            <el-input v-model="mobileForm.userName" placeholder="输入姓名、手机号" style="font-size: 18px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="regist-btn-submit" type="primary" @click="mobileFormSubmit()" style="font-size: 18px">查询</el-button>
          </el-form-item>
        </el-form>
    </el-tabs>
  <!-- </el-col> -->
  <!-- <el-col :span="6"> </el-col> -->
  </div>
</template>

<script>
// import VDistpicker from "v-distpicker";
export default {
  // components: { VDistpicker },
  data() {
    return {
      // isHiddenSuccess: true,
      // isHiddenError: true,
      labelPosition: 'top',
      isClose: false,
      message: "",
      activeName: "phone",
      mobileForm: {
        userName: "",
      },
      dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    // this.isHiddenSuccess = true;
    // this.isHiddenError = true;
  },
  methods: {

    // 提交表单--手机号注册
    mobileFormSubmit() {
        // 根据uname、idcard、or phone查询
      this.$http({
        url: this.$http.adornUrl("/personal/clouddoctor/app/getCheckRecord"),
        method: "get",
        params: this.$http.adornParams({
          uname: this.mobileForm.userName,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$router.replace({ name: "report_query_2",
          query: {
            "uid": data.uid,
            "staticRecordId": data.sid,
            "staticRecordCreatetime": data.create_time,
            "staticRecordDuration": 20,
            "crossPlatform": true
          } });
        } else {
          console.log(data);
          this.$message({
            message: "查询失败，请检查输入是否正确！",
            // message: data.msg,
            type: "warning",
          });
          this.message = data.msg;
        }
      });
    },
    // 转到登录界面
    gotoLogin() {
      this.$router.replace({ name: "login" });
    },
  },
  mounted() { },
};
</script>

<style lang="scss">
// .brand-info__text {
//   text-align: center;
//   // margin: 0 0 22px 0;
//   font-size: 34px;
//   font-weight: 500px;
//   text-transform: uppercase;
// }
.text-home {
  text-align: center;
  font-size: 30px;
  color: firebrick;
  margin-bottom: 10px;
}

.regist-main {
  position: absolute;
  top: 50%;
  left: 50%;
  // padding: 150px 60px 180px;
  width: 550px;
  transform: translate(-50%, -50%);
  // min-height: 100%;
  // background-color: #fff;
}

.regist-title {
  font-size: 24px;
}

.regist-captcha {
  overflow: hidden;

  >img {
    width: 100%;
    cursor: pointer;
  }
}

.login-btn-submit {
  width: 100%;
  font-size: 18px;
  background-color: #428bca;
  border-color: #428bca;
}

.regist-btn-submit {
  width: 100%;
  font-size: 18px;
  // margin-top: 0px;
  // margin-top: 8px;
  background-color: #428bca;
  border-color: #428bca;
}
</style>
<style lang="scss" scoped>
::v-deep .tabs {
  text-align: center;
  top: 50px;
  left: 50px;
  position: relative;
}

::v-deep .el-tabs {
  color: #000;
  left: 0px;
  top: 0px;
  position: relative;
}

/* 去除灰色横条 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/* 设置滑块颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #428bca !important;
}

/* 设置滑块停止位置 */
::v-deep .el-tabs__active-bar.is-top {
  height: 37px;
  width: 125px !important;
  border-radius: 17px;
  top: 0px !important;
  left: -18px !important;
  position: absolute !important;
  z-index: 1;
}

/* 设置当前选中样式 */
::v-deep .el-tabs__item.is-active {
  color: #ffffff !important;
  z-index: 2;
}

/* 设置未被选中样式 */
::v-deep .el-tabs__item {
  padding: 0 20px !important;
  width: 104px;
  box-sizing: border-box;
  display: inline-block;
  position: relative !important;
  color: #000000 !important;
  font-size: 18px;
  z-index: 2;
}
</style>
